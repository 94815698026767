@import "variables";

.flex-spacer {
  flex-grow: 1;
}

body {
  @include body-text();
}

.card-stripe {
  margin: $base-margin-2x -#{$half-margin} $base-margin-2x -#{$half-margin};
  padding: $half-padding;
  background-color: $B13;
}

.card-inverted {
  margin: $base-margin -#{$base-margin} -#{$base-margin} -#{$base-margin};
  padding: $base-padding;
  background-color: $B13;
}

.bottom-margin {
  margin-bottom: $base-margin;
}

.bottom-margin-2x {
  margin-bottom: $base-margin-2x;
}

.half-bottom-margin {
  margin-bottom: $half-margin;
}

.top-margin {
  margin-top: $base-margin;
}

.hidden {
  display: none;
}

.no-wrap {
  white-space: nowrap;
}

.app-card {
  @include app-card;

  .section-title {
    margin-top: 0;
    margin-bottom: $base-margin;
  }
}

.clickable {
  cursor: pointer;
}

// this fixes moving background when showing ng-bootstrap modal
// but enables scrolling
body.modal-open {
  overflow: unset;
}

.app-dropdown-button {
  &::after {
    display: none;
  }
}
