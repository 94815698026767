@import "theme";

.app-table-wrapper {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.app-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $base-margin;
  background-color: transparent;

  th, td {
    padding: $base-padding $half-padding;
    vertical-align: middle;
  }

  th {
    padding-bottom: $half-padding;
  }

  tbody {
    box-shadow: $box-shadow;
    background-color: $B9;

    > tr:not(:last-of-type) {
      border-bottom: $table-border;
    }

    .buttons {
      > * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .table-name {
    @include body-text-bold();
    outline: none;
    cursor: pointer;
  }
}

.page-item {
  margin: $half-margin;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.disabled .page-link {
    padding: $half-padding 0;
    border: none;
    color: $primary;
    background-color: transparent;
  }

  .page-link {
    border-radius: $border-radius;
    font-weight: $font-weight-bold;
    box-shadow: none;
    color: $B4;
  }
}
