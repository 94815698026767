@import "variables";

@mixin body-text() {
  font-family: $font-source-sans;
  font-weight: $font-weight-regular;
  color: $B4;
  font-size: 16px;
  line-height: 1.2em;
}

@mixin body-text-bold() {
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  color: $B4;
  font-size: 16px;
  line-height: 1.2em;
}

@mixin title() {
  font-family: $font-roboto;
  font-weight: $font-weight-black;
  color: $B1;
  font-size: 36px;
  line-height: 1.2em;
}

@mixin subtitle() {
  font-family: $font-source-sans;
  font-weight: $font-weight-regular;
  color: $B4;
  font-size: 18px;
  line-height: 1.2em;
}

@mixin section-title() {
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  color: $B7;
  font-size: 24px;
  line-height: 1.2em;
}

@mixin section-subtitle() {
  font-family: $font-source-sans;
  font-weight: $font-weight-regular;
  color: $B4;
  font-size: 16px;
  line-height: 1.2em;
}

@mixin subsection-title() {
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  color: $B2;
  font-size: 18px;
  line-height: 1.2em;
}

@mixin subsection-subtitle() {
  font-family: $font-source-sans;
  font-weight: $font-weight-regular;
  color: $B4;
  font-size: 14px;
  line-height: 1.2em;
}

@mixin price-sum-text() {
  font-family: $font-roboto;
  font-weight: $font-weight-light;
  color: $B2;
  font-size: 24px;
  line-height: 1.2em;
}

@mixin price-sum-number() {
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  color: $B2;
  font-size: 28px;
  line-height: 1.2em;
}

@mixin form-label() {
  font-family: $font-roboto;
  font-weight: $font-weight-light;
  color: $B2;
  font-size: 16px;
}

@mixin form-input() {
  font-family: $font-source-sans;
  font-weight: $font-weight-bold;
  color: $B2;
  font-size: 16px;
}

@mixin app-card() {
  display: block;
  background-color: $B9;
  box-shadow: $box-shadow;
  padding: $base-padding $base-padding 0 $base-padding;
}
