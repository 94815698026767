@import "mixins";
@import "variables";

form {
  display: flex;
  flex-direction: column;

  label {
    @include form-label;
    margin-bottom: $small-margin;
  }

  .form-control.ng-invalid ~ .tooltip {
    min-width: 10rem;

    .tooltip-inner {
      color: $B9;
      background-color: $B10;
    }

    .arrow::before {
      border-top-color: $B10;
    }
  }

  .input-group-text {
    color: $B7;
    background-color: $public-background;
  }
}

input, textarea {
  @include form-input;
  margin: 0 !important;
  border-color: $B5;

  &.ng-invalid.ng-touched, &.ng-invalid.computed {
    border-color: $B10;
  }

  &.price-sum-number {
    width: 7.5em;
    text-align: right;
  }

  &.form-control:focus {
    border-color: transparentize($B7, 0.1);
    outline: none;
    box-shadow: none;
  }
}

.form-group {
  max-width: 30em;
}

.form-group-short {
  max-width: 15em;
}

.form-group.ngb-dropdown {
  position: relative;

  ngb-typeahead-window.dropdown-menu {
    left: 0 !important;
    width: 100%;
    max-height: 315px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid $B5;
    background-color: $B13;
  }

  input {
    text-align: left;
    background-image: url('../assets/img/chevron-down.png');
    background-repeat: no-repeat;
    background-position: calc(100% - #{$base-margin}) center;
    background-size: .75em;
  }
}

.dropdown-item {
  overflow: hidden;
  font-family: $font-source-sans;
  font-weight: $font-weight-regular;
  line-height: 36px;
  text-overflow: ellipsis;
  cursor: pointer;
  color: $B2;
}

.dropdown-item.active, .dropdown-item:active {
  border-color: transparentize($B7, 0.1);
  font-weight: $font-weight-bold;
  color: $B9;
  background-color: $B3;
}

.inputWithIcon {
  position: relative;

  app-icon {
    position: absolute;
    bottom: .7rem;
    left: 1rem;
    color: $B5;
  }

  input {
    padding-left: $base-padding-3x;

    &:focus + app-icon {
      color: $B7;
    }
  }
}

.inputWithRightIcon {
  position: relative;

  app-icon {
    position: absolute;
    right: 1rem;
    bottom: .7rem;
    color: $B5;
  }

  input {
    padding-right: $base-padding-3x;

    &:focus + app-icon {
      color: $B7;
    }
  }
}


.checkbox {
  margin: $half-margin 0;
  font-weight: $font-weight-bold;
  cursor: pointer;

  > app-icon {
    margin-left: $base-margin;
  }

  > div {
    overflow: hidden;
  }

  .checkbox-icon {
    flex: 0 0 auto;
    width: $checkbox-size;
    height: $checkbox-size;
    margin-right: $half-margin;
    border: 1px solid $B5;
    border-radius: $border-radius;

    &.checked {
      border-color: $B7;
      color: $B9;
      background-color: $B7;
    }
  }

  .checkbox-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $B2;
  }

  .disabled {
    font-weight: $font-weight-regular;
    cursor: not-allowed;

    .checkbox-label {
      opacity: .7;
      color: $B4;
    }

    .description {
      opacity: .7;
    }

    .checkbox-icon {
      background-color: $disabled-backgroud;

      &.checked {
        border: $disabled-border;
      }
    }
  }
}


.tooltip.show {
  min-width: 10rem;

  .tooltip-inner {
    color: $B9;
    background-color: $B8;
  }

  .arrow::before {
    top: -1px;
    border-top-color: $B8;
  }
}

.ng-select.custom {

  &.ng-select-focused {
    > .ng-select-container {
      border-color: $B7 !important;
      box-shadow: none !important;
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      cursor: initial;
    }
  }

  .ng-select-container {
    padding: 0.375rem 0.75rem;
    border: 1px solid $B5;
    border-radius: $border-radius;
    text-align: left;
    box-shadow: none;
    cursor: pointer;
    background-image: url('../assets/img/chevron-down.png');
    background-repeat: no-repeat;
    background-position: calc(100% - #{$base-margin}) center;
    background-size: .75em;

    .ng-value-container {
      padding: 0;
      font-family: $font-source-sans;
      font-weight: $font-weight-bold;
      color: $B2;
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-dropdown-panel.ng-select-top {
    margin-bottom: 0;
  }

  .ng-dropdown-panel.ng-select-bottom {
    margin-top: 0;
  }

  .ng-dropdown-panel {

    .ng-dropdown-panel-items {

      .ng-option, .ng-option.ng-option-selected, .ng-option.ng-option-marked {
        font-family: $font-source-sans;
        font-weight: $font-weight-regular;
        line-height: 36px;
        color: $B2;
      }

      .ng-option.ng-option-selected {
        color: $B2;
        background-color: transparent;

        .ng-option-label {
          font-weight: $font-weight-regular;
        }
      }

      .ng-option.ng-option-marked {
        color: $B9;
        background-color: $B3;

        .ng-option-label {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      padding-bottom: calc(.375em - 5px);

      .ng-value-container .ng-value {
        display: flex;
        padding: 5px;
        border: none;
        border-radius: 3px;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        color: $B7;
        background-color: $button-light-background;

        .ng-value-icon {
          order: 2;
          border: none;
          color: $B4;

          &:hover {
            background-color: $button-light-background;
          }
        }

        .ng-value-label {
          order: 1;
          border: none;
        }
      }
    }

    .ng-dropdown-panel { //TODO find some better solution - workaround for empty dropdown userd in equipment group
      z-index: 1000;
      margin-top: -3px;

      .ng-dropdown-panel-items .ng-option.ng-option-disabled {
        display: none;
      }
    }
  }
}

.custom-control {
  margin-right: 0;
  padding-left: $base-padding-2x;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: 2px solid $B7;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  border: 1px solid $B5;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $B9;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2300AFD0'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23999999'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $disabled-backgroud;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: $disabled-backgroud;
}

.custom-control-label::before {
  top: -0.2rem;
  left: -$base-padding-2x;
  width: 1.5rem;
  height: 1.5rem;
  border: $disabled-border;
  box-shadow: none !important;
  background-color: $B9;
}

.custom-control-label::after {
  top: -0.2rem;
  left: -$base-padding-2x;
  width: 1.5rem;
  height: 1.5rem;
}
