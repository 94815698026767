@import "typography";
@import "theme";

button.btn, a.btn {
  font-weight: $font-weight-bold;

  &:focus {
    box-shadow: none !important;
  }

  &.btn-light {
    color: $button-light-color;
    background-color: $button-light-background;
  }

  &.btn-link {
    font-weight: $font-weight-regular;
    text-decoration: underline;
    color: $B4;
  }
}
