@import "mixins";
@import "variables";

.body-text {
  @include body-text();
}

.body-text-bold {
  @include body-text-bold();
}

.title {
  @include title();
}

.subtitle {
  @include subtitle();
}

.section-title {
  @include section-title();
  margin-top: $base-margin-2x;
}

.section-subtitle {
  @include section-subtitle();
  margin-bottom: $base-margin-2x;
}

.subsection-title {
  @include subsection-title();
  margin-top: $base-margin-2x;
}

.subsection-subtitle {
  @include subsection-subtitle();
  margin-bottom: $base-margin;
}

.price-sum-text {
  @include price-sum-text();
}

.price-sum-number {
  @include price-sum-number();
  white-space: nowrap;
}

.form-label {
  @include form-label();
}
